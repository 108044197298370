export default {
	props: {
		nonEditable: {
			type: Boolean,
		},
	},
	methods: {
		handleClick(event) {
			if (this.editable) {
				event.stopPropagation();
				event.preventDefault();

				const closestDataPath = event.target.closest("[data-path]")?.dataset.path;

				useGizmoStore().getImageCallback = (result) => {
					result.src = "/binaries/" + result.src.split("/binaries/")[1];

					let oldValue = {
						src: event.target.src?.split("/binaries/")[1],
						alt: event.target.alt,
					};

					if (oldValue.src) oldValue.src = "/binaries/" + oldValue.src;

					event.target.dispatchEvent(
						new CustomEvent("contentChanged", {
							detail: {
								oldValue,
								unknownProp: true,
								value: result,
							},
							bubbles: true,
							composed: true,
						}),
					);
				};

				parent.postMessage(
					{
						action: "getImage",
						payload: {
							closestDataPath,
							value: {
								src: event.target.src,
								alt: event.target.alt,
							},
						},
					},
					"*",
				);
			}
		},
	},
	computed: {
		editable() {
			return this.$config.public.previewMode && !this.nonEditable && !this.$store.state.selectTriggerElement;
		},
	},
};
