<template>
	<picture
		class="responsive-image"
		:class="{ 'editable-image': $config.public.previewMode }"
		@click="handleClick"
	>
		<NuxtSourceSet
			:src="xs"
			media="screen and (max-width: 767px)"
			:modifiers="modifiers"
			:height="mobileDimensions.height"
			:width="mobileDimensions.width"
			v-if="xs"
		/>
		<NuxtSourceSet
			:src="md"
			media="screen and (min-width: 1220px)"
			:modifiers="modifiers"
			:height="mobileDimensions.height"
			:width="mobileDimensions.width"
			v-if="md"
		/>
		<NuxtImg
			:src="mainSrc"
			:width="mainDimensions.width"
			:modifiers="modifiers"
			:alt="alt"
			:loading="eager ? 'eager' : 'lazy'"
			:height="mainDimensions.height"
		/>
	</picture>
</template>

<script>
import SupportsDarkMode from "../../../../mixins/platform/SupportsDarkMode.js";
import InlineEditableResponsiveImageMixin from "~/components/platform/inline-editing/InlineEditableResponsiveImageMixin.js";

const formatSource = (src) => {
	if (src && typeof src === "string") {
		const parts = src.split("/");
		parts[parts.length - 1] = parts[parts.length - 1].split("?")[0];
		parts[parts.length - 1] = encodeURIComponent(parts[parts.length - 1]);
		return parts.join("/");
	} else return src;
};

const getDimensions = (src) => {
	const groups = src.match(/w=(\d+)&h=(\d+)/);

	if (groups) {
		return {
			width: groups[1],
			height: groups[2],
		};
	}

	return {};
};

export default defineNuxtComponent({
	name: "ImageResponsive",
	props: {
		alt: {
			type: String,
			default: "Alternative descriptive text",
		},
		xs: {
			type: String,
			default: "",
		},
		md: {
			type: String,
			default: "",
		},
		image_xs: {
			type: Object,
		},
		image_md: {
			type: Object,
		},
		eager: {
			type: Boolean,
		},
		modifiers: {
			type: Object,
		},
	},

	mixins: [SupportsDarkMode, InlineEditableResponsiveImageMixin],

	computed: {
		rawMainSrc() {
			return this.md || (this.image_md && this.image_md.src);
		},
		rawMobileSrc() {
			return this.xs || (this.image_xs && this.image_xs.src);
		},
		mainDimensions() {
			if (this.rawMainSrc) return getDimensions(this.rawMainSrc);
			return { width: undefined, height: undefined };
		},
		mobileDimensions() {
			if (this.rawMobileSrc) return getDimensions(this.rawMobileSrc);
			return { width: undefined, height: undefined };
		},
		mainSrc() {
			if (this.rawMainSrc) {
				return formatSource(this.rawMainSrc);
			}
		},
		mobileSrc() {
			if (this.rawMobileSrc) return formatSource(this.rawMobileSrc);
		},
	},
});
</script>

<style lang="scss" scoped>
picture {
	:deep(source) {
		width: 100%;
		height: auto;
	}

	:deep(img) {
		display: block;
		max-width: 100%;
		width: 100%;
		height: auto;
	}
}
</style>
